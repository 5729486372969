<template>
    <div class="media_gallary">
      <div class="md-layout-item md-layout md-gutter pl-0 pr-0 pb-2 p-1 mt-2 m-3 header">
          <div class="md-layout-item flex align-center text-center p-0">
              <FileInputComponent @input="input"/>
          </div>
      </div>
      <Gallary @input="input1" :getMaterials="getMaterials"/>
    </div>
</template>

<script>
import FileInputComponent from "@/components/atom/form/mediaInputComponent";
import Gallary from "@/components/atom/form/Gallary";
import {
 
} from "@/components"
import { putObject} from "@/store/upload";
const AWS = require('aws-sdk');
const spacesBaseUrl = new AWS.Endpoint(process.env.VUE_APP_DIGITALOCEAN_SPACES_ENDPOINT);
const urlMarge = spacesBaseUrl.protocol+'//'+process.env.VUE_APP_DIGITALOCEAN_SPACES_BUCKET+'.'+spacesBaseUrl.host
const baseUrl =urlMarge+'/'+process.env.VUE_APP_DIGITALOCEAN_SPACES_FOLDER+'/'
import {mapActions,mapGetters } from "vuex";
export default {
  name: "media",
  components: {
    FileInputComponent,
    Gallary,
   
  },
  data() {
    return {
      url: null ,
      path:this.$route.query.type,
      material_id: '',
      form: {
        file_type: "",
        file_name: "",
        thumbnail_url: "",
        file_path: "",
        file_url: "",
        is_downloadable: true,
        material_description: "",
        is_special: false,
        is_hidden_from_student: false,
        active: true,
        tag_data: [],
        file_for: ''
      }
    }
  },
   props: {
    type_name: {
      type: Object
    },
  },
  computed: {
    ...mapGetters({
      getMaterials: "material/getMaterials",
    }),
  },
  methods: {
        ...mapActions({
          actMaterialsCreate: "material/actMaterialsCreate",
          actMaterialList: "material/actMaterialList",
          actGetMaterials: "material/actMaterials",
        }),
        input(val){
          this.url = {url: URL.createObjectURL(val[0]),type: val[0].type,name: val[0].name};
          console.log('url',this.url,'file',val[0])
          this.$emit('input',val);
          //call file
          let CustomData = val[0];
          this.CustomData = URL.createObjectURL(CustomData)
          // put file hare
          this.filepath = this.type_name.name+'/'+this.url.type+'/'+this.type_name.id;
          putObject(CustomData, this.path+'/'+this.filepath, "public-read").then(
            setTimeout(this.onSubmit(), 1500)
          )
        },
        onSubmit(){
          const type = this.url.type.split('/')
          console.log(type,'cheked',this.url.name)
          if(type[0] === 'application'){
            if(type[1] === 'pdf'){
                this.form.file_type = type[1].toUpperCase()
                console.log('this.form.file_type',this.form.file_type)
            } else {
                this.form.file_type = 'DOCX'
                console.log('this.form.file_type1',this.form.file_type)
            }
             
          }
          if(type[0] != 'application') {
            const types = this.url.type.split('/')
            this.form.file_type = types[0].toUpperCase(),
            console.log(types[0].toUpperCase(),'chek2')
          }
          this.form.file_name = this.url.name,
          this.form.file_path = this.type_name.name+'/'+this.url.type+'/'+this.type_name.id+'/'+this.url.name,
          this.form.file_url = baseUrl+this.path+'/'+this.type_name.name+'/'+this.url.type+'/'+this.type_name.id+'/'+this.url.name,
          this.form.file_for = this.type_name.name + ''
          this.actMaterialsCreate(this.form)
            .then(({material_id }) => {
              console.log(material_id);
              this.material_id = material_id
              this.actMaterialList(``);
          }).catch(error => alert(error))
        },
        input1(val){
         console.log(val)
         this.$emit('input',val);
        },
        onDetails() {
        },
  },
  created() {
    this.actMaterialList(``);
  }
};
</script>
