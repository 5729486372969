<template>
  <div>
    <h2 class="text-uppercase text-victoria mb-3">{{ label }}</h2>
    <ul >
      <li v-for="(item, index) in items" :key="index" class="my-3 flex align-center">
        <md-checkbox v-model="array" :value="index"> </md-checkbox>
        <InputFieldComponent v-model="item.title" @HandleTargetValue="HandleTargetValue(index)"  class="w-74" />
        <span v-show="isCreateQuiz">
          <span v-show="items.length > 2" @click="deleteRow(index)"><i class="md-icon md-icon-font pointer ml-1 text-red">remove_circle</i></span>
          <span v-show="items.length - 1 === index" @click="addRow(index)"><i class="md-icon md-icon-font ml-1 pointer text-success">add_box</i></span>
        </span>
       </li>
    </ul>
    <b id="scrollTop" class="text-danger" v-if="message">{{ message }}</b>
  </div>
</template>

<script>
import { InputFieldComponent } from '@/components'
export default {
  components: {
    InputFieldComponent
  },
  data: () => ({
    array: [],
    is_correct_answer: [],
    form: {
        full_marks: null
    },
  }),
  props: {
    label: {
      type: String
    },
    labelOutline: {
      type: Boolean,
      default: () => true
    },
    message: {
      type: String,
      default: () => null
    },
    items: {
      type: Array,
    },
    isCreateQuiz: {
        type: Boolean,
        default: false,
    }
  },
  watch: {
    array(value) {
        this.items.map(item => delete item.drop_down_options);
        this.items.forEach((item) => {
          item.is_correct_answer = false ;
          item.value = item.title;
        });
        for( var i = 0; i < value.length; i++){
          let x = value[i]
          this.items[x].is_correct_answer = true;
        }
        this.$emit('input', this.items); 
    }
  },
  methods:{
    HandleTargetValue(){
      this.$emit('input', this.items);  
    },
    addRow(index) {
      this.id++;
      this.items.push({id: this.items.length + index, label: 'answer Type'});
    },
    deleteRow(id) {
      this.items.splice(id, 1);
    },
    uuidv4() {
      return ([7]+-1).replace(/[8]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    }
  }
};
</script>

