<template>
  <div class="p-2 md-align_center" style="width: 95%">
    <div class="pt-3 pb-3 flex align-center justify-between" style="border-bottom: 1px solid #a6a6a67d">
      <h2 v-if="!createForm">Create New Study Guide</h2>
      <md-button @click="dialog(false)" class="md-icon-button md-dense">
        <md-icon>close</md-icon>
      </md-button>
    </div>
    <div class="md-layout-item md-layout md-size-100">
      <div class="md-layout-item md-size-100">
        <CreateStudyGuide :question_id="question_id" :type_name="type_name"/>
      </div>
    </div>
  </div>
</template>

<script>
import CreateStudyGuide from "@/components/molecule/question-and-test-management/material/StudyGuide/CreateNew.vue";
import {mapMutations} from "vuex";
export default {
  components: {
    CreateStudyGuide,
  },
  props: {
    question_id: {
      type: String
    },
    content: {
      type: Object,
    },
  },
  data() {
      return {
          label: 'Create',
          createForm: false,
          type_name: {
            name:'Guidelines',
            id: 'SG_id'
          },
      }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
  }
};
</script>

<style></style>
