<template>
    <div class="md-gutter container grid">
        <div class="flex justify-between">
            <h3 class="mb-2 flex align-center">
                <img class="mr-1" src="https://img.icons8.com/material/24/000000/opened-folder--v1.png"/>
                Material Gallary
            </h3>
            <SearchBox
            class="p-1 pl-2 w-64 w-xs-48 m-2"
            v-model="keyword"
            placeholder="Search"
            @submit="submitSearchKeyword"
            />
        </div>
        <div class="md-layout-item md-gutter">
            <span v-for="img in getMaterials" :key="img.id">
                <div @click="selItem(img)"
                :class="{ clicked: opened.includes(img.id) }" class="md-layout-item gallery">
                    <a> 
                        <span v-if="img.file_type === 'IMAGE'">
                            <img width="242" height="363" :src="img.file_url" alt="material image">
                        </span>
                        <span class="relative" v-if="img.file_type === 'PDF'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">picture_as_pdf</md-icon>
                        </span>
                        <span class="relative" v-if="img.file_type === 'DOCX'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">text_snippet</md-icon>
                             <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 56px;">Docs</p>
                        </span>
                        <span class="relative" v-if="img.file_type == 'AUDIO'">
                             <md-icon class="flex align-center text-victoria" style="font-size:55px !important">audiotrack</md-icon>
                            <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 51px;">Audio</p>
                        </span>
                        <span class="relative" v-if="img.file_type == 'VIDEO'">
                            <md-icon class="flex align-center text-victoria" style="font-size:55px !important">ondemand_video</md-icon>
                            <p class="flex align-center text-victoria" style="position: absolute;;top: 85px;left: 55px;">Video</p>
                        </span>
                    
                    </a>
                    <p class="image_sub_title">{{img.file_name}}</p>
                </div>
            </span>
        </div>
        <div class="mt-2 pb-2 ml-1" style="border-bottom: 1px solid rgba(166, 166, 166, 0.49);zoom: 90%;">
            <Paginate
            :start="meta.current_page"
            :end="meta.last_page"
            :total="meta.last_page"
            :limit="meta.per_page"
            @on-start="onStart"
            @on-end="onEnd"
            />
        </div>
    </div>
</template>
<script>
import {
    Paginate,
    } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import { mapGetters,mapActions} from "vuex";
import { query } from "@/utils/queryString";
export default {
    components:{
        SearchBox,
        Paginate,
    },
  data() {
    return {
        urls: '',
        opened:[],
        checkedItems:[],
        merge:[],
        data:[],
        query: '?',
        keyword: '',
    }
  },
  computed: {
      ...mapGetters({
        getMaterials: "material/getMaterials",
        meta: "material/getMaterialMeta",
      }),
  },
    watch: {
    keyword(oldvalue, newValue) {
        query.set('file_name', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actMaterialList(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actMaterialList(`?${query.get()}`);
        }
    }
  },
  methods: {
    ...mapActions({
        actMaterialList: "material/actMaterialList",
    }),
    submitSearchKeyword() {
      this.actMaterialList(`?${query.set('file_name', this.keyword)}`);
    },
    selItem(item){
         const index = this.opened.indexOf(item.id);
            if(index > -1){
                this.opened.splice(index,1) 
            } else {
                this.opened.splice(index-1,1) 
                this.opened.push(item.id);
                //console.log('check',item)
                this.$emit('input',item);
            }
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actMaterialList(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actMaterialList(`?${query.get()}`);
    },
  },
  created() {
    this.actMaterialList(``);
  }

}
</script>